<template>
  <el-dialog
    class="dialog"
    :title="title"
    :visible="true"
    :close-on-click-modal="false"
    width="40%"
    @close="$emit('close')"
  >
    <el-progress type="circle" :color="statusColor" :percentage="percentage" :status="progressStatus" />
    <div>
      <div v-if="inProgress">{{ processingMsg }}</div>
      <div v-if="!inProgress && !isError">{{ successMsg }}</div>
      <div v-if="!inProgress && isError">{{ errorMessage }}</div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'ProcessDialog',

  props: {
    title: String,
    percentage: Number,
    inProgress: Boolean,
    errorMessage: String,
    isError: Boolean,
    processingMsg: String,
    successMsg: String,
  },
  computed: {
    progressStatus () {
      // if (this.inProgress) return 'info'
      if (this.isError) return 'warning'
      return 'success'
    },

    statusColor () {
      if (this.isError) return '#D72C4A'
      return '#2bb8c1'
    },
  },

}
</script>

<style scoped lang="scss">
.dialog {
  text-align: center;
}
</style>
