import { get } from 'lodash'

export const showErrorMsg = (err, customError) => {
  if (err) {
    const customErrMsg = get(customError, err)
    if (customErrMsg) window.$message.error(customErrMsg)
    else window.$message.error(err)
    return true
  }
  return false
}
