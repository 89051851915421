<template>
  <div class="company-contact-menu">
    <PageTitleExtra
      v-if="contactMenuInfo.id"
      title="通訊錄列表"
      btn="新增"
      btn2="匯入"
      btn3="匯出"
      @btn3Click="dialog.export = true"
      @btn2Click="dialog.import = true"
      @btnClick="dialog.create = true"
    />

    <FiltersContainer v-if="contactMenuInfo.id">
      <el-input
        v-model="search.all"
        clearable
        placeholder="搜尋通訊錄"
        @clear="refresh(true)"
        @keypress.enter.native="refresh"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
      <MemberSearch placeholder="搜尋企業聯絡人" :model.sync="search.member" @change="refresh" @clear="refresh(true)" />
    </FiltersContainer>

    <div v-if="!loading && !contactMenuInfo.id">
      <el-form>
        <el-form-item label="是否開啟企業通訊錄：">
          <el-button @click="register">開啟</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div v-loading="loading">
      <el-table v-if="contactMenuInfo.id" :data="showTableData" row-key="id">
        <EmptyBlock slot="empty" />
        <el-table-column label="統一編號" width="150" prop="taxId" align="center" fixed="left" />
        <el-table-column label="公司名稱" width="180" prop="name" align="center" fixed="left" />
        <el-table-column
          v-for="(row) in sortSchema"
          :key="row.key"
          :label="row.text"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.customize[row.key] || '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="操作"
          fixed="right"
          width="110"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="檢視"
              @edit="onRowEdit(scope.row)"
              @delete="onRowDelete(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="changePage"
    />
    <!-- Dialog -->
    <DropImportDialog v-if="dialog.import" @close="dialog.import = false" @upload="onCheckImport" />
    <EditCompanyDialog
      v-if="dialog.create"
      :schema="tableSchema"
      @close="dialog.create = false"
      @refresh="refresh"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      allRange
      @close="dialog.export = false"
      @export="dialog.export = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
    <DeleteDialog
      v-if="dialog.delete"
      title="警示"
      content="確定要刪除？刪除後將無法復原"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteShopContactMenu"
    />
    <ProcessDialog
      v-if="dialog.processing"
      :title="processing.title"
      :inProgress="processing.inProgress"
      :isError="processing.isError"
      :percentage="processing.percentage"
      @close="dialog.processing = false"
    />
    <WarningDialog
      v-if="dialog.confirmImport"
      title="匯入執行確認"
      hideCancel
      btnString="確認匯入"
      @confirm="onConfirmImport"
      @close="dialog.confirmImport = false"
    >
      <template slot="body">
        <div>
          <p>本次匯入：預計執行匯入筆數 {{ get(processing, 'detail.rowPreparedImportCount', 0) }} 筆。</p>
          <br>
          <p>請確認是否執行匯入？</p>
        </div>
      </template>
    </WarningDialog>
    <WarningDialog
      v-if="dialog.importSuccess"
      title="匯入成功"
      hideCancel
      btnString="確認"
      @confirm="dialog.importSuccess = false, refresh(true)"
    >
      <template slot="body">
        <div>
          <p v-if="get(processing, 'detail.rowMissedCount', 0) === 0">檔案匯入成功，本次匯入筆數 {{ get(processing, 'detail.rowImportCount', 0) }} 筆。</p>

          <div v-else>
            <p>檔案匯入成功，本次匯入筆數 {{ get(processing, 'detail.rowImportCount', 0) }} 筆，未匯入筆數 {{ get(processing, 'detail.rowMissedCount', 0) }} 筆。</p>
            <p>請務必下載本次未匯入名單進行調整後再次嘗試匯入：未匯入名單檔案</p>
          </div>
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// component
import MemberSearch from '@/components/Search/MemberSearch.vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import DropImportDialog from './components/DropImportDialog.vue'
import EditCompanyDialog from '../components/EditCompanyDialog.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import ProcessDialog from '@/components/Dialog/ProcessDialog.vue'
// api
import {
  RegisterCompanyContactMenu,
  FindCompanyContactMenu,
  GetShopContactMenuSchema,
  GetShopContactMenu,
  GetShopContactMenuCount,
  DeleteShopContactMenu,
} from '@/api/company/contactMenu'
import { CreateImportTask, FindImport, ImportFile } from '@/api/import'
// mixin
import tableMixin from '@/mixin/table'
import ExportMixin from '@/mixin/export'
// utils
import { getAllDataFromApi } from '@/utils/helper'
import dayjs from 'dayjs'
import { get } from 'lodash'

export default defineComponent({
  name: 'CompanyContactMenu',
  components: {
    PageTitleExtra,
    DropImportDialog,
    EditCompanyDialog,
    ExportOptionsDialog,
    ExportDialog,
    MemberSearch,
    WarningDialog,
    ProcessDialog,
  },
  mixins: [tableMixin, ExportMixin],
  data: () => ({
    loading: false,
    contactMenuInfo: {},
    search: {
      all: null,
      member: null,
      searching: false,
    },
    warning: {
      type: null,
      error: null,
      total: 0,
      success: 0,
      fail: 0,
    },
    processing: {
      task: null,
      detail: null,
      title: '',
      inProgress: false,
      isError: false,
      percentage: 0,
    },
    tableSchema: [],
    tableData: [],
  }),
  computed: {
    showTableData () {
      const data = this.tableData
      return data.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
    },
    sortSchema () {
      const list = []
      for (const key in this.tableSchema) {
        list.push({
          key,
          ...this.tableSchema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      return list
    },
  },
  async mounted () {
    this.$store.commit('app/SET_SIDEBAR', false)
    this.loading = true
    this.extendDialog(['import', 'export', 'processing', 'confirmImport', 'importSuccess'])
    await this.getShopContactMenuInfo()
    if (this.contactMenuInfo.id) {
      await this.getShopContactMenuSchema()
      await Promise.all([
        this.getShopContactMenu(),
        this.getShopContactMenuCount(),
      ])
    }
    this.loading = false
  },
  methods: {
    get,
    async refresh (clear) {
      this.loading = true
      if (clear) this.tableOptions.page = 1
      await Promise.all([
        this.getShopContactMenu(),
        this.getShopContactMenuCount(),
      ])
      this.loading = false
    },
    async register () {
      if (!this.contactMenuInfo.id) {
        this.loading = true
        const [, err] = await RegisterCompanyContactMenu({
          shopId: this.shop,
        })
        this.loading = false
        if (this.showErrorMsg(err)) return
        await this.getShopContactMenuInfo()
        await this.getShopContactMenuSchema()
        await Promise.all([
          this.getShopContactMenu(),
          this.getShopContactMenuCount(),
        ])
      }
    },
    async getShopContactMenuInfo () {
      const [res, err] = await FindCompanyContactMenu({ shopId: this.shop })
      if (this.showErrorMsg(err)) return
      this.contactMenuInfo = res
    },
    async getShopContactMenuSchema () {
      const [res, err] = await GetShopContactMenuSchema({
        shopId: this.shop,
      })
      if (this.showErrorMsg(err)) return
      this.tableSchema = res.customize
    },
    async getShopContactMenu () {
      if (this.search.all || this.search.member) {
        if (!this.search.searching) {
          this.tableOptions.page = 1
          this.search.searching = true
        }
      }
      if (!this.search.all && !this.search.member) this.search.searching = false
      const [res, err] = await GetShopContactMenu({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        search: this.search.all || undefined,
        memberId: get(this.search, 'member.id') || undefined,
      })
      if (this.showErrorMsg(err)) return
      this.tableData = res
    },
    async getShopContactMenuCount () {
      const [res, err] = await GetShopContactMenuCount({
        shopId: this.shop,
        search: this.search.all || undefined,
        memberId: get(this.search, 'member.id') || undefined,
      })
      if (this.showErrorMsg(err)) return
      this.tableOptions.dataCount = res.count
    },
    async deleteShopContactMenu () {
      const [, err] = await DeleteShopContactMenu({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (this.showErrorMsg(err)) return
      this.$message.success('刪除成功')
      await this.refresh()
      this.dialog.delete = false
    },
    onRowEdit (row) {
      this.selectRow = row
      this.$router.push({
        name: 'CompanyDetail',
        query: {
          companyId: row.id,
          tab: 'contactMenu',
        },
      })
    },
    onRowDelete (row) {
      this.selectRow = row
      this.dialog.delete = true
    },
    async changePage () {
      await this.refresh()
    },
    // ----------- Export -----------
    async getExportData () {
      const payload = {
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        search: this.search.all || undefined,
        memberId: get(this.search, 'member.id') || undefined,
      }

      return await getAllDataFromApi(
        this.exportTotal,
        GetShopContactMenu,
        payload,
        true,
      )
    },
    resetProcessing () {
      this.processing.task = null
      this.processing.title = ''
      this.processing.percentage = 0
      this.processing.isError = false
      this.processing.inProgress = false
    },
    async prepareExport () {
      this.resetExport()
      // 取得匯出數據數量
      const [res, err] = await GetShopContactMenuCount({
        shopId: this.shop,
        search: this.search.all || undefined,
        memberId: get(this.search, 'member.id') || undefined,
      })
      if (this.showErrorMsg(err)) return
      this.exportTotal = res.count
      if (!this.exportTotal) {
        this.$message.warning('沒有資料可以匯出')
        return
      }
      this.showExportDialog = true
      this.exportting = true
      const [data, getErr] = await this.getExportData()
      if (this.showErrorMsg(getErr)) return
      this.exportData = data
      await this.formatExportData()
      this.exportting = false
    },
    async formatExportData () {
      const data = []
      let count = 0
      this.exportData.forEach(item => {
        const row = {
          統一編號: item.taxId,
          公司名稱: item.name,
        }
        for (const attr of this.sortSchema) {
          const label = attr.text
          row[label] = item.customize[attr.key]
        }
        data.push(row)
        count++
        this.exportPercentage = (count / this.exportTotal) * 100
      })
      this.ExportExcel(data, '企業通訊錄', '企業通訊錄')
    },

    async checkTaskStatus (task, type) {
      this.processing.task = task
      const [taskDetail, findErr] = await FindImport({
        shopId: this.shop,
        id: task.id,
      })
      this.processing.detail = taskDetail
      if (this.showErrorMsg(findErr)) return

      if (type === 'check') {
        this.processing.title = '檢查中，請稍候'
        this.processing.percentage = taskDetail.checkProgress
        this.processing.isError = Boolean(taskDetail.importFailedAt)
      }
      if (type === 'import') {
        this.processing.title = '匯入中，請稍候'
        this.processing.percentage = taskDetail.importProgress
        this.processing.isError = Boolean(taskDetail.checkFailedAt)
      }
      this.processing.inProgress = !this.processing.isError

      const status = taskDetail.state

      if (status === 'FAIL') {
        this.processing.title = '匯入發生錯誤！'
        this.processing.inProgress = false
        this.processing.isError = true
        return
      }
      if (status === 'CANCEL') {
        // this.processing.title = '匯入發生錯誤！'
        // this.processing.inProgress = false
        // this.processing.isError = true
        return
      }

      if (status === 'PENDING' || status === 'REVIEW') {
        // 待處理 -> 跳轉匯入處理頁
        this.$router.push({
          name: 'ContactMenuImportResult',
          params: {
            taskId: task.id,
          },
        })
      } else if (status === 'CHECKED') {
        console.log('CHECKED')
        this.dialog.confirmImport = true
        this.dialog.processing = false
      } else if (status === 'DONE') {
        console.log('DONE')
        this.dialog.processing = false
        this.dialog.importSuccess = true
      } else if (status !== 'DONE' || status !== 'CANCEL') {
        // 未完成或未取消 -> 繼續檢查
        setTimeout(async () => {
          await this.checkTaskStatus(task, 'import')
        }, 2000)
      }
    },
    async onCheckImport (result) {
      if (!result) return
      this.resetProcessing()
      // 創建匯入任務
      const [task, taskError] = await CreateImportTask({
        shopId: this.shop,
        type: 'CompanyDirectoryFirm',
        fileId: result.id,
      })
      if (this.showErrorMsg(taskError)) return
      this.dialog.import = false
      // 檢查匯入狀態
      this.dialog.processing = true
      await this.checkTaskStatus(task, 'check')
    },

    async onConfirmImport () {
      this.dialog.processing = true
      this.dialog.confirmImport = false
      this.processing.title = '匯入中，請稍候'
      // 正式匯入
      const [res, err] = await ImportFile({
        shopId: this.shop,
        id: this.processing.task.id,
      })
      if (this.showErrorMsg(err)) return
      console.log(res)
      await this.checkTaskStatus(this.processing.task, 'import')
    },
  },
})
</script>

<style lang="postcss" scoped>

</style>
